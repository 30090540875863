'use client';

import { Button } from '@/components/ui/button';
import { signInWithOAuth } from '@/utils/auth-helpers/client';
import { type Provider } from '@supabase/supabase-js';
import { FaGithub } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { useState } from 'react';

interface SeparatorProps {
  text: string;
}

function SeparatorWithText({ text }: SeparatorProps) {
  return (
    <div className="relative">
      <div className="relative flex items-center py-1">
        <div className="grow border-t border-zinc-700"></div>
        <span className="mx-3 shrink text-sm leading-8 text-zinc-500">
          {text}
        </span>
        <div className="grow border-t border-zinc-700"></div>
      </div>
    </div>
  );
}

type OAuthProviders = {
  name: Provider;
  displayName: string;
  icon: JSX.Element;
};

export default function OauthSignIn() {
  const oAuthProviders: OAuthProviders[] = [
    {
      name: 'google',
      displayName: 'Google',
      icon: <FcGoogle className="h-6 w-6" />
    },
    {
      name: 'github',
      displayName: 'GitHub',
      icon: <FaGithub className="h-5 w-5 ml-1 mr-0.5" />
    }
    /* Add desired OAuth providers here */
  ];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOAuthSignIn = async (provider: Provider) => {
    setIsSubmitting(true);
    await signInWithOAuth(provider);
    setIsSubmitting(false);
  };

  return (
    <div>
      <div className="mt-1 flex flex-col gap-2">
        {oAuthProviders.map((provider) => (
          <Button
            key={provider.name}
            variant="outline"
            onClick={() => handleOAuthSignIn(provider.name)}
            className="w-full"
            disabled={isSubmitting}
          >
            <span className="mr-2">{provider.icon}</span>
            <span>Login with {provider.displayName}</span>
          </Button>
        ))}
      </div>
      <SeparatorWithText text="Or" />
    </div>
  );
}
